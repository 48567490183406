import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { Onboarding } from 'types/onboarding';

const initialState: Onboarding = {
  utmData: {
    campaign: '',
    medium: '',
    source: '',
    content: '',
    term: '',
    gclid: '',
    _gl: '',
  },
  contactInfo: {
    address: '',
    name: '',
    email: '',
    phone: '',
  },
  address: '',
  addressData: {
    city: '',
    county: '',
    state: '',
    street_address: '',
    zip_code: '',
  },
  addressSelected: false,
  lead_source: '',
  referrer: '',
  cameFromRebateEligibility: false,
  homeDataId: '',
  homeDetails: {
    area_in_sqft: '',
    basement_height: '',
    basementRooms: 0,
    bath_count: '',
    bath_count_total: '',
    bath_partial_count: 0,
    commonAreaLayout: '',
    cooling_system: '',
    firstFloorBathrooms: 0,
    firstFloorBedrooms: 0,
    firstFloorOther: 0,
    fourthFloorBathrooms: 0,
    fourthFloorBedrooms: 0,
    fourthFloorOther: 0,
    fuel_type: '',
    fully_finished_basement: '',
    heating_system: '',
    home_style: '',
    home_type: '',
    id: '',
    municipal_utilities: false,
    numberOfFloors: 0,
    secondFloorBathrooms: 0,
    secondFloorBedrooms: 0,
    secondFloorOther: 0,
    thirdFloorBathrooms: 0,
    thirdFloorBedrooms: 0,
    thirdFloorOther: 0,
    units_count: 0,
    year_built: '',
    zones: '',
    electric_provider: '',
    ny_dac_eligible: false,
    waterHeaterAge: '',
    acAge: '',
  },
  customerGoals: {
    repair_hvac: false,
    upgrade_hvac: false,
    upgrade_water_heater: false,
    learn: false,
    add_ac: false,
    fix_parts_of_home: false,
    other: false,
    otherGoal: '',
  },
  notCurrentlyOfferingRepairs: '',
  notWaterHeaterOnly: '',
  fuelAssistance: '',
  utilityProvider: '',
  notEligibleForIncentives: '',
  productInterest: {
    heat_pump: false,
    boiler: false,
    furnace_and_or_ac: false,
    hot_water_heater: false,
    learn_heat_pumps: false,
  },
  isSystemBroken: '',
  acRanking: '',
  carbonFootprintRanking: '',
  costVsEfficiencyRanking: '',
  unevenlyHeatedRooms: '',
  systemPreference: {
    heat_pumps: false,
    heat_pumps_with_backup_system: false,
    same_kind_of_system_I_have: false,
  },
  typeOfHeatPump: '',
  homeOccupancy: '',
  summerHomeInfo: '',
  heaConfirmation: '',
  heaInfo: '',
  insulation: '',
  insulationRequirement: '',
  timeline: '',
  ac: '',
  systemAge: '',
  oilHeating: '',
  oilToHeatPump: '',
  chpExpectations: '',
  dmsExpectationsChoice: '',
  dmsExpectationsCeilingCassette: '',
  numberOfCondensers: 0,
  numberOfThermostats: 0,
  systemLocation: '',
  installLocationHeight: '',
  oilTankRemoval: '',
  firstFloorRooms: {
    kitchen_living: false,
    bedrooms: false,
    bathrooms: false,
    other: false,
  },
  secondFloorRooms: {
    kitchen_living: false,
    bedrooms: false,
    bathrooms: false,
    other: false,
  },
  thirdFloorRooms: {
    kitchen_living: false,
    bedrooms: false,
    bathrooms: false,
    other: false,
  },
  fourthFloorRooms: {
    kitchen_living: false,
    bedrooms: false,
    bathrooms: false,
    other: false,
  },
  commonAreaFloor: 0,
  otherRooms: {
    three_season_room: false,
    mudroom: false,
    garage: false,
    other: false,
  },
  ceilingCassetteRooms: '',
  barrierRemediation: '',
  householdIncome: '',
  householdMembers: '',
  whatIsAHea: '',
  nyRebateProgram: '',
  nyMaxRebateValue: 0,
  selectedSystem: {
    badge: '',
    final_price: '',
    fuel: 'None',
    highlighted_features: [],
    loan: {
      duration: 7,
      yearly_rate: '',
      name: 'None',
    },
    manufacturer: '',
    model_family: '',
    monthly_payment: '',
    our_pick: false,
    photo: '',
    provides: 'None',
    system_name: 'None',
    system_rebate: '',
    system_type: 'None',
    tax_incentive: 0,
    upfront_price: '',
    whats_included: [],
  },
  suggestedSystems: [],
  paymentType: 'loan',
  waterHeaterUpgrade: '',
  preferenceRanking: [
    'lower-utility-bills',
    'get-off-fossil-fuels',
    'lowest-upfront-price',
    'roi',
    'add-central-ac',
  ],
};

export const onboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<Partial<Onboarding>>) => {
      return merge({}, state, action.payload);
    },
    updateSelectedSystem: (
      state,
      action: PayloadAction<Partial<Onboarding>>
    ) => {
      return {
        ...state,
        selectedSystem: action.payload.selectedSystem,
      };
    },
    updateSuggestedSystems: (
      state,
      action: PayloadAction<Partial<Onboarding>>
    ) => {
      return {
        ...state,
        suggestedSystems: action.payload.suggestedSystems,
      };
    },
  },
});

export const { updateByPayload, updateSelectedSystem, updateSuggestedSystems } =
  onboarding.actions;

export default onboarding.reducer;
